import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import appstore from './assets/icons/appstore.png'
import playstore from './assets/icons/playstore.png'
import gradientBg480x600 from './assets/bgs/green-gradient-bg-480x600.svg'
import gradientBgClipped480x600 from './assets/bgs/green-gradient-bg-clipped-480x600.svg'
import gradientBgClipped1440x216 from './assets/bgs/green-gradient-bg-clipped-1440x216.svg'
import BgWideLines from './assets/bgs/green-gradient-bg-lines-only-1440x216.svg'
import gradientBgWide from './assets/bgs/green-gradient-bg-wide-422x400.svg'
import lineBgMobile from './assets/bgs/line-bg.svg'
import lineBgMobileLight from './assets/bgs/line-bg-light.svg'

import mobileIphone01 from './assets/images/mobile-iphone-01-376x764.png'
import mobileIphone02 from './assets/images/mobile-iphone-02-376x764.png'
import mobileIphone03 from './assets/images/mobile-iphone-03-376x764.png'
import mobileIphone04 from './assets/images/mobile-iphone-04-376x764.png'
import mobileIphone05 from './assets/images/mobile-iphone-05-376x764.png'
import mobileIphone06 from './assets/images/mobile-iphone-06-376x764.png'
import mobileIphone07 from './assets/images/mobile-iphone-07-376x764.png'
import mobileIphone08 from './assets/images/mobile-iphone-08-376x764.png'
import mobileIphone09 from './assets/images/mobile-iphone-09-376x764.png'
import mobileIphone10 from './assets/images/mobile-iphone-10-376x764.png'
import mobileIphone11 from './assets/images/mobile-iphone-11-376x764.png'
import mobileIphone12 from './assets/images/mobile-iphone-12-376x764.png'
import mobileIphone13 from './assets/images/mobile-iphone-13-376x764.png'
import mobileIphone14 from './assets/images/mobile-iphone-14-376x764.png'
import mobileIphone15 from './assets/images/mobile-iphone-15-376x764.png'
import mobileIphone16 from './assets/images/mobile-iphone-16-376x764.png'


import desktopIphone01 from './assets/images/desktop-iphone-01-376x764.png'
import desktopIphone02 from './assets/images/desktop-iphone-02-376x764.png'
import desktopIphone03 from './assets/images/desktop-iphone-03-376x764.png'
import desktopIphone04 from './assets/images/desktop-iphone-04-376x764.png'
import desktopIphone05 from './assets/images/desktop-iphone-05-376x764.png'
import desktopIphone06 from './assets/images/desktop-iphone-06-376x764.png'
import desktopIphone07 from './assets/images/desktop-iphone-07-376x764.png'
import desktopIphone08 from './assets/images/desktop-iphone-08-376x764.png'
import desktopIphone09 from './assets/images/desktop-iphone-09-376x764.png'
import desktopIphone10 from './assets/images/desktop-iphone-10-376x764.png'
import desktopIphone11 from './assets/images/desktop-iphone-11-376x764.png'
import desktopIphone12 from './assets/images/desktop-iphone-12-376x764.png'
import desktopIphone13 from './assets/images/desktop-iphone-13-376x764.png'
import desktopIphone14 from './assets/images/desktop-iphone-14-376x764.png'
import desktopIphone15 from './assets/images/desktop-iphone-15-376x764.png'
import desktopIphone16 from './assets/images/desktop-iphone-16-376x764.png'
import btnPrevHover from './assets/icons/prev-hover.svg'
import btnPrev from './assets/icons/prev.svg'
import btnNextHover from './assets/icons/next-hover.svg'
import btnNext from './assets/icons/next.svg'
import carouselRadioBlur from './assets/icons/dot-blur.svg'
import carouselRadioFocus from './assets/icons/dot-focus.svg'
import exampleAlbum from './assets/images/album-example-bon-jovi-64x64.png'
import GridRow from './locust/composables/GridRow';
import Hide from './locust/composables/Hide';
import ContainerSplit from './locust/composables/ContainerSplit';
import desktopPolaroidX2 from './assets/images/desktop-polaroid-x2.png'
import desktopPolaroidX3 from './assets/images/desktop-polaroid-x3.png'
import mobilePolaroidX3 from './assets/images/mobile-polaroid-x3.png'
import desktopiPhoneInHand from './assets/images/desktop-iphone-in-hand.png'
import XY from './locust/composables/XY';
import "@fontsource/lato"; 
import "@fontsource/inter"; 
import "@fontsource/inter/700.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/500.css";
import { ChevronDown } from '@styled-icons/fa-solid/ChevronDown'
import X from './locust/composables/X';
import Facebook from './assets/icons/facebook.svg'
import Twitter from './assets/icons/twitter.svg'
import Instagram from './assets/icons/instagram.svg'
import DropShadow, { onHover } from './locust/components/DropShadow';
import HockeyIcon from './assets/icons/hockey.png'
import {Close} from '@styled-icons/ionicons-outline/Close'
import {MenuOutline  } from '@styled-icons/evaicons-outline/MenuOutline'
import { css } from 'glamor';
import useSwipe from "./locust/composables/Swipe";


const THEME_COLOR : string = '#008080'
const THEME_TEXT : string = '#072424'
const THEME_BG : string = '#F8FBEC'

const getFontLato = () => ({ fontFamily: "Lato" })
const getFontInter = () => ({ fontFamily: "Inter" })
const getDummyText = () => 'Lorem ipsum dolor sit amet, consectetur adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam'

const Sp = ( props : { h ?: number | string }) => <div style={{ width : '100%', height : props.h ? props.h : 50  }}/> 

function App() {
  return (
    <>
      <Hide on={1050}>
          <DesktopVersion/>
      </Hide>
      <Hide b4={1050}>
          <MobileVersion/>
      </Hide>
    </>
  );
}

const ExamplePlaylist = ( props : { titles : { title : string, band : string, href ?: string }[] }) => {
    return (
        <>
          {props.titles.map(( item : any, index : number )=>{
            return <div>
              <SoftLink href={item.href}><ExamplePlaylistItem key={index} title={item.title} subtitle={item.band}/></SoftLink>
              <Sp h={7}/>
            </div>
          })}
        </>
    )
}

const SoftLink = ( props : { href : string, children ?: any }) => <a href={props.href}>{props.children}</a>
const TitleI = ( props : { children ?: any }) => <h1 style={{  display : 'inline-block', ...getFontInter(),  fontSize : 32, fontWeight : 700, color : THEME_COLOR }}>{props.children}</h1>
const TitleII = ( props : { children ?: any }) => <h2 style={{  display : 'inline-block', ...getFontInter(),  fontSize : 24, fontWeight : 600, color : THEME_COLOR }}>{props.children}</h2>
const TitleFooter = ( props : { children ?: any, style ?: any }) => <h2 style={{  display : 'inline-block', ...getFontInter(),  fontSize : 24, fontWeight : 600, color : 'white', ...props.style }}>{props.children}</h2>
const TitleBullet = ( props : { children ?: any }) => <h2 style={{  display : 'inline-block', ...getFontInter(),  fontSize : 16,  fontWeight : 500, color : '#072424' }}>{props.children}</h2>
const TextBullet = ( props : { children ?: any }) => <h2 style={{  lineHeight : '24px', display : 'inline-block', ...getFontLato(),  fontSize : 16, fontWeight : 300, color : '#072424' }}>{props.children}</h2>
const SubtitleI = ( props : { children ?: any }) => <p style={{  lineHeight : '20px', display : 'inline-block',...getFontLato(), fontSize : 14, color : '#072424' }}>{props.children}</p>
const SubtitleLarge = ( props : { children ?: any }) => <p style={{  display : 'inline-block',...getFontInter(), fontSize : 14,  color : '#072424' }}>{props.children}</p>
const LinkText = ( props : { children ?: any, href ?: string  }) => <a href={props.href} style={{ lineHeight : '24px', display : 'inline-block',...getFontInter(), fontSize : 16, fontWeight : 500, color : THEME_COLOR }}>{props.children}</a>
const LinkFake = ( props : { children ?: any, onClick ?: () => void }) => <div onClick={props.onClick} style={{ cursor : 'pointer', lineHeight : '24px', display : 'inline-block',...getFontInter(), fontSize : 16, fontWeight : 500, color : THEME_COLOR }}>{props.children}</div>
const Email = ( props : { children ?: any, href ?: string }) => <a href={props.href}  style={{  display : 'inline-block', ...getFontInter(),  fontSize : 16,  fontWeight : 500, color : 'white' }}>{props.children}</a>
const TextI = ( props : { style ?: any, children ?: any }) => <p style={{ lineHeight : '20px', display : 'inline-block', ...getFontLato(), fontSize : 14,  color : '#072424', ...props.style  }}>{props.children}</p>


const links : any = {
  appleAppStore : 'https://apps.apple.com/us/app/ice-hockey-dads-playlist/id1327662038',
  googlePlayStore : 'https://play.google.com/store/apps/details?id=com.laurentbutre.HockeyMom',
  twitter : 'https://twitter.com/IceHockeyDadDJ',
  facebook : 'https://www.facebook.com/DadPlaylist',
  instagram : 'https://www.instagram.com/icehockeyrinkmusicdj/',
  email : 'mailto:ceo@laurentbutre.com',
}

const content : any = {
    mobileTitle : `Ice Hockey Dads' Playlist`,
    landingTitle : 'Master the Arena Soundtrack Like Never Before',
    landingSubtitle : "One button, one perfect song start position. Easily play the right part of the song as soon as the referee blow his whistle", 
    sectionOneTitle : "How It All Started",
    sectionOneDescription : {
      p1 : "As a passionate hockey parent just like you, I've crafted Ice Hockey Dads' Playlist to transform game days. Imagine being in the scoreboard booth, feeling the excitement and warmth underneath. With this app, I do more than play music – I create the arena's heartbeat.",
      p1_truncated : "As a passionate hockey parent just like you, I've crafted Ice Hockey Dads' Playlist to transform game days. Imagine being in the scoreboard booth, feeling the excitement and warmth underneath. With this app, I do more..",
      p2 : "Syncing each song's start time with game moments, I turn the rink into an energetic stage. Plugging into the audio system, I amplify the game, inspiring players and fans alike. It's not just music; it's an immersive experience.",
      p3 : "Join me on this journey. Let's be the ones who elevate games into memories. With Ice Hockey Dads' Playlist, we're not just watching – we're crafting unforgettable moments for our kids, one beat at a time."
    },
    sectionTwoTitle : "Inside the App",
    sectionTwoSubtitle : "Interface Snapshot",
    sectionThreeTitle : "F.A.Q.",
    sectionThreeSubtitle : "Your Questions Answered",
    sectionThreeItems : [
      {
        q : "My songs are not playing. How do I fix that?",
        a : `The hardware silence button of your phone will dominate Application settings. So first make sure your phone is not on silent mode (toggle button on the side, and remove "Do not disturb mode"). Then, make sure the application volume (top bar on the main screen) is not set to 0.`
      },
      {
        q : "How can I set the perfect start time for each button?",
        a : "For each button, you can set the perfect start time. I am still working on the user experience for the Setup Screen, therefore it is not yet the most convenient. Here is my trick to set the correct start up time. First select your period (or screen of buttons). Second, select the song from your library that you want to set on a button. Then play the song up to the key timing, when you hear the transition you want, press stop to pause the song. Rewind or go a notch further if you wish, until you are paused at the right time. Then press the button you want to assign that song with that specific start time. Sometimes, I will let the song play, and press every second the button to assign the song until I hear the right moment I want to memorize as a start time."
      },
      {
        q : "How to restore purchases after re-installing the app?",
        a : `If you have re-installed the application and need to restore your purchases, simply go to the parameters screen and click on "Restore Purchases"`
      },
      { 
        q : "How can I get a refund for the app?",
        a : <> If the app is not satisfying you, or you download it by mistake, no need to complain on the AppStore comments, simply get reimbursed. Here are the steps to get refunded on the <LinkText href="https://support.apple.com/en-us/HT204084">Apple App Store</LinkText> or on the <LinkText href="https://support.google.com/googleplay/workflow/9813244?hl=en">Google PlayStore</LinkText>.</>
      },
      {
        q : "How can I provide feedback or contact you directly?",
        a : 'If you love the app, please leave a comment on the AppStore, this will make my day, and I will be super happy. If you are not happy of the App, or you think some features are missing to make a great day at the rink, please feel free to contact me directly at ceo@laurentbutre.com'
      },
      {
        q : "Why isn't Spotify integrated into the app's song library?",
        a : `Many of you have been asking me to integrate Spotify as a source for the songs library. I did my best, this is by the way, how "Hockey Mom" App got life, as I developed it specifically to integrate Spotify library. When I submitted the app to Spotify (which was working through the Spotify API), I received from Spotify a denial to publish. They stated that my application usage was in breach of their T&Cs. I appealed and lost the appeal. Therefore I am not able to integrate Spotify in Ice Hockey Dad's Playlist, nor Hockey Mom App. Sorry, I tried.`
      },
      {
        q : "Which source of songs can I use?",
        a : `Ice Hockey Dad's Playlist works best with the local MP3 library. This is when you have loaded your songs on your iTunes application from your computer and have synchronized them with your phone. They work best for two reasons: First, if the rink doesn't have wifi or 4G coverage, which happens often when the rings are in basements or within metal buildings, then your streaming sources will not work. Second, it seems that iTunes Cloud Libraries are changing their songs links from time to time and will break the memorized buttons.

        Alternatively, you can use iTunes Cloud Subscription as a source for your songs. For some reasons, even if you have downloaded the songs locally on your device, you will still need to have connectivity when using the Application. It seems that iTunes will still want to check the DRM (Digital Right Management) of each songs before playing them on Ice Hockey Dad's Playlist.`
      },
      {
        q : "Some buttons don't play anymore",
        a : `If you have checked the volume, mute and do not disturb modes, then it is possible that iTunes Cloud Library has changed the reference of the song. It happens once every few years, or if you have changed your Library country.`
      },
      {
        q : "Why internet needed for iTunes Cloud songs?",
        a : `If you are using iTunes Cloud Subscription to select your songs on the Application, you will then need to have connectivity during the game in order to play and stream the songs. Even if you have downloaded them locally on your phone, it seems that Apple is trying to verify the DRM (Digital Right Management) of each song before playing them on Ice Hockey Dad's Playlist.`
      }      
    ],
    sectionFourTitle : "Selected Playlist",
    sectionFourSubtitle : "Get Inspired!",
    sectionFourDescription : {
      p1 : `Here is my playlist for my son's hockey games. Pick yours in the "Ice Hockey Dad's Playlist" App and select your perfect start time for each button. Seamlessly link music to your app buttons – whether it's by importing MP3s to your local iTunes library or embracing the convenience of iTunes Cloud Music subscription.`,
      p2 : `Don't have a subscription at the moment? No worries, simply`,
      p3 : "Elevate your Kid's Hockey Games and make them truly unforgettable."
    },
    footer : {
      title : 'Ready to Rock the Rink?',
      subtile : 'Get the App Now!'
    },
    playlists : {
       a :[
        
        /* bad link -> */ { title : 'Runaway', band : '', href : 'https://geo.itunes.apple.com/us/album/runaway/397237803?i=397238252&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Kernkraft 400', band : '', href : 'https://geo.itunes.apple.com/us/album/kernkraft-400-sport-chant-stadium-remix/129611454?i=129611482&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Barracuda', band : '', href : 'https://geo.itunes.apple.com/us/album/barracuda/192690716?i=192691055&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Gangnam Style', band : '', href : 'https://geo.itunes.apple.com/us/album/gangnam-style-%EA%B0%95%EB%82%A8%EC%8A%A4%ED%83%80%EC%9D%BC/560398387?i=560398388&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
      ],
       b:[
        /* bad link -> */ { title : 'Sandstorm', band : '', href : 'https://geo.itunes.apple.com/us/album/sandstorm/256773617?i=256773619&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Wake Me Up', band : '', href : 'https://geo.itunes.apple.com/us/album/wake-me-up/677375839?i=677376024&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Get Lucky', band : '', href : 'https://geo.itunes.apple.com/us/album/get-lucky-feat-pharrell-williams-nile-rodgers/617154241?i=617154366&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Sport Organ - Game Time Addams Family', band : '', href : 'https://geo.itunes.apple.com/us/album/addams-family-chicago-blackhawks-stadium-organ-version/996207638?i=996207642&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
      ],
       c :[
        { title : 'Black Betty', band : '', href : 'https://geo.itunes.apple.com/us/album/black-betty/216088276?i=216088341&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        { title : 'Centuries', band : '', href : 'https://geo.itunes.apple.com/us/album/centuries/948754194?i=948754235&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        /* bad link -> */ { title : "I'm Shipping up to Boston", band : '', href : 'https://geo.itunes.apple.com/us/album/im-shipping-up-to-boston/78964671?i=78964625&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
        /* bad link -> */ { title : 'Seven Nation Army', band : '', href : 'https://geo.itunes.apple.com/us/album/seven-nation-army/275230921?i=275230945&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj'}, 
      ]
    }

}




const Center = ( props : { text ?: any, children ?: any, childWidth ?: number  }) => {
  return (
      <div style={{ width : '100%', textAlign : 'center'}}>
          <div style={{ display : 'inline-block', textAlign : props.text ? 'center' : 'left', width : props.childWidth ? props.childWidth : ''}}>
              { props.children }
          </div>
      </div>
  )
}


const MobileHeaderMenuButton = ( props : { onClick : () => void, children ?: any }) =>  <div onClick={props.onClick}{...css({ 
    cursor : 'pointer', width : '100%', height : 64, paddingLeft : 16, [onHover]: { backgroundColor : THEME_BG }}
  )}>
  <GridRow height={64}>
      { props.children }
    </GridRow>
</div>

const MobileHeaderMenu = ( props : { 
    getRefs : () => any
}) => {
  const [toggle, setToggle] = useState( false )

  return (
    <>
      <div onClick={()=>{
          setToggle(!toggle)
      }} style={{ float : 'right', marginRight : 8  }}>
        <GridRow height={64}>
          <XY>
              { toggle ? 
                <Close size={30} color={THEME_TEXT}/> : 
                <MenuOutline style={{transform : 'translate(-2px,0)' }} size={26} color={THEME_TEXT}/> 
              }
          </XY>
          </GridRow>
      </div>

      <div style={{ marginTop : 1, overflow : 'hidden', transition : '200ms', height : toggle ?  64 * 4 : 0, width : '100%', backgroundColor : 'white'}}>
            <MobileHeaderMenuButton onClick={()=>{
              props.getRefs()[ 'How it started' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
              setTimeout(()=>{ setToggle(false)},500)
            }}>
                How it started
            </MobileHeaderMenuButton>
            <MobileHeaderMenuButton onClick={()=>{
                props.getRefs()[ 'Gallery' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                setTimeout(()=>{ setToggle(false)},500)
            }}>
                Gallery
            </MobileHeaderMenuButton>
            <MobileHeaderMenuButton onClick={()=>{
                props.getRefs()[ 'FAQ' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                setTimeout(()=>{ setToggle(false)},500)
            }}>
                FAQ
            </MobileHeaderMenuButton>
            <MobileHeaderMenuButton onClick={()=>{
                props.getRefs()[ 'Playlist' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                setTimeout(()=>{ setToggle(false)},500)
            }}>
                Playlist
            </MobileHeaderMenuButton>
      </div>
    </>
  )
}

const MobileHeader = ( props : { 
    getRefs : () => any
}) => <DropShadow style={{ width : '100%', height : 64 }}><div style={{ height : 64, width : '100%', backgroundColor : '#F8FBEC'}}>
  <GridRow height={64}>
    <img style={{ marginLeft : 16 }} height={32} width={32} src={HockeyIcon} alt=""/>
  </GridRow>  
  <MobileHeaderMenu getRefs={props.getRefs}/>

</div></DropShadow>


const DesktopHeader = ( props : { 
  getRefs : () => any
}) => <DropShadow style={{ width : '100%', height : 64 }}>
    <div style={{ height : 64, width : '100%', backgroundColor : '#F8FBEC'}}>
      <Center>
        <div style={{ height : 64, width : 1040, display : 'inline-block' }}>
            <GridRow height={64}>
                <img style={{ marginLeft : 16, cursor : 'pointer',  }} height={32} width={32} src={HockeyIcon} alt=""/>
            </GridRow>  
            <GridRow height={64}>
                <h3 style={{ ...getFontInter(), marginLeft : 16, fontSize : 20, maxWidth : 280, fontStyle : 'italic'}}>{content.mobileTitle}</h3>
            </GridRow>  
            <div style={{ float : 'right', marginLeft : 10, marginRight : 10 }}>
                  <MobileHeaderMenuButton onClick={()=>{
                      props.getRefs()[ 'Playlist' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      //setTimeout(()=>{ setToggle(false)},500)
                  }}>
                      Playlist
                  </MobileHeaderMenuButton>
            </div>
            <div style={{ float : 'right', marginLeft : 10, marginRight : 10 }}>
            <MobileHeaderMenuButton onClick={()=>{
                      props.getRefs()[ 'FAQ' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      //setTimeout(()=>{ setToggle(false)},500)
                  }}>
                      FAQ
                  </MobileHeaderMenuButton>
            </div>
            <div style={{ float : 'right', marginLeft : 10, marginRight : 10 }}>
                <MobileHeaderMenuButton onClick={()=>{
                    props.getRefs()[ 'Gallery' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    //setTimeout(()=>{ setToggle(false)},500)
                }}>
                    Gallery
                </MobileHeaderMenuButton>
            </div>
            <div style={{ float : 'right', marginLeft : 10, marginRight : 10 }}>
              <MobileHeaderMenuButton onClick={()=>{
                  props.getRefs()[ 'How it started' ].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  //setTimeout(()=>{ setToggle(false)},500)
                }}>
                    How it started
                </MobileHeaderMenuButton>
            </div>
        </div>
      </Center>
    </div>
</DropShadow>

const TextReveal = () => {

    const [ toggle1, setToggle1 ] = useState(false)
    const [ toggle2, setToggle2 ] = useState(false)
    const [ transition, setTransition ] = useState( 500 )

    useEffect(()=>{
        if(!toggle2 && toggle1 ) setTimeout(()=>{
          setToggle1(false)
          setTimeout(()=>{
            setTransition(500)
          },300)
        },225)        
      
    },[ toggle1, toggle2 ])

    return (
      <div>

              <TextI>
                { toggle1 ? content.sectionOneDescription.p1 : content.sectionOneDescription.p1_truncated }
              </TextI>
              
              { !toggle1 && <Center text childWidth={280}>
                    <Sp h={15}/>
                    <LinkFake onClick={()=>{
                        setToggle2(true);
                        setToggle1(true);
                    }}>
                        Read More
                    </LinkFake>
                </Center> 
              }

              {
                 <div style={{ overflow : 'hidden', height : 'auto', maxHeight : toggle2 ? 1000 : 0, transition : `${transition}ms`}}>
                   <Sp h={15}/>

                    <TextI>
                      {content.sectionOneDescription.p2}
                    </TextI>

                    <Sp h={15}/>

                    <TextI>
                      {content.sectionOneDescription.p3}
                    </TextI>


                    <Center text childWidth={280}>
                      <Sp h={15}/>
                      <LinkFake onClick={()=>{
                          setTransition(300);
                          setToggle2(false)
                        }}>
                          Read Less
                      </LinkFake>
                    </Center> 

                
                </div>
              }
             

      </div>

    )


}

const MobileVersion = () => {

  const refs : any = {
      'How it started'  : useRef(null),
      'Gallery'         : useRef(null),
      'FAQ'             : useRef(null),
      'Playlist'        : useRef(null),
  }

  const getRefs = () => refs
  const scale : number = 0.6

  return (
    <>
      <MobileHeader getRefs={getRefs}/>
      <Sp h={15}/>
      <div style={{ padding : 20, paddingBottom : 0 }}>
        <Center text childWidth={280}>
          <h3 style={{ ...getFontInter(), fontSize : 20, maxWidth : 280, fontStyle : 'italic'}}>{content.mobileTitle}</h3>
          <Sp h={15}/>
          <TitleI>{content.landingTitle}</TitleI>
          <Sp h={15}/>
          <SubtitleI>{content.landingSubtitle}</SubtitleI>
        </Center>

        <Sp h={30}/>
        
        <Center>
            <SoftLink href={links.appleAppStore}>
                <img width={180} height={52} src={appstore} alt=""/>
            </SoftLink>
        </Center>

        <Sp h={10}/>

        <Center>
            <SoftLink href={links.googlePlayStore}>
              <img width={180} height={52} src={playstore} alt=""/>
            </SoftLink>
        </Center>

        <Sp h={110}/>
        
        <Center>
          
            <div style={{  display : 'inline-block', width : 320, height : 400, backgroundRepeat : 'no-repeat', backgroundSize : '100%', backgroundImage : `url(${gradientBg480x600})` }}>
                <XY>
                    <div style={{  
                        transform : 'translate(0px, 0px)', 
                        display : 'inline-block', 
                        width : 280 * 1, 
                        height : 369 * 1, 
                        backgroundRepeat : 'no-repeat', 
                        backgroundSize : '100%', 
                        backgroundImage : `url(${mobilePolaroidX3})` 
                    }}/>
                </XY>
            </div>
          
        </Center>
        <div ref={getRefs()['How it started']}/>
        <Center text childWidth={280}>
              
              <Sp h={30}/>

              <TitleII>
                {content.sectionOneTitle}
              </TitleII>
                
              <Sp h={15}/>
              
              <TextReveal/>

        </Center>
        </div>

        <div>
        <Sp h={110}/>
        <div ref={getRefs()['Gallery']}/>
        <Center>
            <TitleII>{content.sectionTwoTitle}</TitleII>
        </Center>
        <Sp h={7.5}/>
        <Center>
            <SubtitleI>{content.sectionTwoSubtitle}</SubtitleI>
        </Center>
        <Sp h={10}/>


        
            
        
        <div style={{ width : '100vw', overflow : 'hidden'}}>
          <div style={{ display : 'inline-block', width : '100%', backgroundSize : '100% auto', backgroundRepeat : 'no-repeat', backgroundPosition : 'top', backgroundImage : `url(${ lineBgMobile })`}}>
              
              <Sp h={30}/>
              
              <Center>
                <StaticCarouselRadioGroup 
                  items={[
                    <img width={376*scale} height={764*scale} src={mobileIphone01} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone02} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone03} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone04} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone05} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone06} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone07} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone08} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone09} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone10} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone11} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone12} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone13} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone14} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone15} alt=""/>,
                    <img width={376*scale} height={764*scale} src={mobileIphone16} alt=""/>,
                  ]}
                />
                </Center>
              

              
          </div>
        </div>

                
        <Sp h={110}/>
        <div ref={getRefs()['FAQ']}/>
        <Center>
            <TitleII>{content.sectionThreeTitle}</TitleII>
        </Center>
        <Sp h={7.5}/>
        <Center>
            <SubtitleLarge>{content.sectionThreeSubtitle}</SubtitleLarge>
        </Center>
       
        
        <Sp h={30}/>


        <Center>

        { content.sectionThreeItems.map(( item : any, index : number )=><Center><FaqItem key={index} title={item.q}>{item.a}</FaqItem></Center>)}
        
        </Center>
        
        
        <Sp h={110}/>
        <div ref={getRefs()['Playlist']}/>
        <Center>
            <TitleII>{content.sectionFourTitle}</TitleII>
        </Center>
        <Sp h={7.5}/>
        <Center>
            <SubtitleLarge>{content.sectionFourSubtitle}</SubtitleLarge>
        </Center>

        <Sp h={15}/>

        <Center text childWidth={280}>
          <TextI>
            {content.sectionFourDescription.p1}
          </TextI>
        </Center>

        <Sp h={15}/>
        
        <Center text childWidth={280}>
          <TextI>
            {content.sectionFourDescription.p2}
          </TextI>
        </Center>
        
        <Center text childWidth={280}>
          <LinkText href={"https://geo.itunes.apple.com/us/album/rock-n-roll-part-2/326120857?i=326120870&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj"}>
            click here
          </LinkText>
        </Center>

        <Sp h={15}/>
        <Center text childWidth={280}>
          <TextI style={{ fontStyle : 'italic' }}>
            {content.sectionFourDescription.p3}
          </TextI>
        </Center>
        
        <Sp h={60}/>
        
        <Center>
            <StaticCarouselRadioGroup sx={{ top : { width : 330 }}}height={421}
                items={[
                    <ExamplePlaylist titles={content.playlists.a}/>,
                    <ExamplePlaylist titles={content.playlists.b}/>,
                    <ExamplePlaylist titles={content.playlists.c}/>,
                ]}
            />
        </Center>

        <Sp h={90}/>

        <div style={{ display : 'inline-block', width : '100%', minHeight: "400px", backgroundPosition : '0 20px', backgroundRepeat : 'no-repeat', backgroundSize : '100%', backgroundImage : `url(${gradientBgClipped480x600})` }}>
          <Sp h={60}/>
          <Center text childWidth={280}>
            <TitleFooter>
              {content.footer.title}{' '}{content.footer.subtitle}
            </TitleFooter> 
          </Center>     
          <Sp h={35}/>
        
          <Center>
            <SoftLink href={links.appleAppStore}>
              <img width={180} height={52} src={appstore} alt=""/>
            </SoftLink>
          </Center>

          <Sp h={10}/>

          <Center>
              <SoftLink href={links.googlePlayStore}>
                  <img width={180} height={52} src={playstore} alt=""/>
              </SoftLink>
          </Center>
          
          <Sp h={35}/>
          <div style={{ width : '100%', textAlign : 'center', height : 30, backgroundColor : ''}}>
              <div style={{  width : 180, display : 'inline-block'}}>
                <X align='space-between'>
                  
                  <SoftLink href={links.twitter}><img src={Twitter}/></SoftLink>
                  <SoftLink href={links.facebook}><img src={Facebook}/></SoftLink>
                  <SoftLink href={links.instagram}><img src={Instagram}/></SoftLink>
                  
                </X>
              </div>
          </div>
          
          
          <Sp h={20}/>
          <Center>
            <SoftLink href={links.email}>
                <Email>
                    {links.email}
                </Email>
            </SoftLink>
          </Center>
          
          
          
        </div>

      </div>
    </>
  )
}


const DesktopVersion = () => {


  const refs : any = {
    'How it started'    : useRef(null),
    'Gallery'  : useRef(null),
    'FAQ'  : useRef(null),
    'Playlist'    : useRef(null),
  }

  const getRefs = () => refs

  const scale : number = 0.8

  return (
    <>
      <DesktopHeader getRefs={getRefs}/>
      <div style={{ padding : 20, paddingBottom : 0, textAlign : 'center' }}>
        <div style={{ display : 'inline-block', width : 1040 }}>
            <ContainerSplit widthRight={510}>
              <div style={{ display : 'inline-block', textAlign : 'left', maxWidth : 500, marginLeft : -30  }}>
                <Sp h={200}/>
                <TitleI>{content.landingTitle}</TitleI>
                <Sp h={15}/>
                <SubtitleI>{content.landingSubtitle}</SubtitleI>
                <Sp h={30}/>
                <div style={{ width : '100%'}}>
                    <SoftLink href={links.appleAppStore}>
                        <img width={180} height={52} style={{ marginRight : 15 }} src={appstore} alt=""/>
                    </SoftLink>
                    <SoftLink href={links.googlePlayStore}>
                        <img width={180} height={52} style={{ marginLeft : 15 }} src={playstore} alt=""/>
                    </SoftLink>
                </div>

            </div>
            <div style={{  display : 'inline-block', width : 480*0.9, height : 600*0.9, backgroundRepeat : 'no-repeat', backgroundSize : '100%', backgroundImage : `url(${gradientBg480x600})` }}>
                <div style={{  transform : 'translate(-130px, 137px)', display : 'inline-block', width : 480*0.6, height : 670*0.6, backgroundRepeat : 'no-repeat', backgroundSize : '100%', backgroundImage : `url(${desktopiPhoneInHand})` }}>
                
                  
                </div>
                  
            </div>
          </ContainerSplit>
      </div>
        
      <Sp h={100}/>

      <div style={{ display : 'inline-block', width : 1040, textAlign : 'left'}}>
        <ContainerSplit invert widthRight={442}>
              <div style={{ width : 442, height : 400, display : 'inline-block'}}>
                  <div style={{  display : 'inline-block', width : 422, height : 400, backgroundRepeat : 'no-repeat', backgroundSize : '100%', backgroundImage : `url(${gradientBgWide})` }}>
                      <div style={{  
                          transform : 'translate(0px, 20px)', 
                          display : 'inline-block', 
                          width : 720*0.5, 
                          height : 731*0.5, 
                          backgroundRepeat : 'no-repeat', 
                          backgroundSize : '100%', 
                          backgroundImage : `url(${desktopPolaroidX3})` 
                          }}
                      />
                  </div>
              </div>
              
              <div style={{ display : 'inline-block', textAlign : 'left', maxWidth : 520  }}>
                <div style={{ position : 'absolute', transform : 'translate(0px,-20px)'}} ref={getRefs()['How it started']}/>
                <Sp h={80}/>
              
                <TitleII>
                  {content.sectionOneTitle}
                </TitleII>
                <Sp h={13}/>

                <TextI>
                  {content.sectionOneDescription.p1}
                </TextI>

                <Sp h={13}/>

                <TextI>
                  {content.sectionOneDescription.p2}
                </TextI>

                <Sp h={13}/>

                <TextI>
                  {content.sectionOneDescription.p3}
                </TextI>
              </div>
        </ContainerSplit>
      </div>
      <Sp h={110}/>
        
        
      
        

        <div style={{ position : 'absolute', transform : 'translate(0px,-20px)'}} ref={getRefs()['Gallery']}/>
        <Center>
            <TitleII>{content.sectionTwoTitle}</TitleII>
        </Center>
        <Sp h={7.5}/>
        <Center>
            <SubtitleI>{content.sectionTwoSubtitle}</SubtitleI>
        </Center>
        <Sp h={30}/>


        
          <Center>
          
          <StaticCarouselRadioGroup desktop
            items={[
              <img width={376*scale} height={764*scale} src={desktopIphone01} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone02} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone03} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone04} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone05} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone06} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone07} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone08} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone09} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone10} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone11} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone12} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone13} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone14} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone15} alt=""/>,
              <img width={376*scale} height={764*scale} src={desktopIphone16} alt=""/>,
            ]}
          />
          </Center>
        
        </div>
        <Sp h={110}/>
          
        <div style={{ position : 'absolute', transform : 'translate(0px,-20px)'}} ref={getRefs()['FAQ']}/>

        <Center>
          <div style={{ display : 'inline-block', width : 1040, textAlign : 'left', backgroundColor : ''}}>
            <ContainerSplit widthRight={600}>
              <div>
                <img height={600} src={desktopPolaroidX2} alt=""/>
              </div>
              <div style={{ textAlign : 'left'}}>
                <TitleII>{content.sectionThreeTitle}</TitleII>
                <Sp h={7.5}/>
                <SubtitleLarge>{content.sectionThreeSubtitle}</SubtitleLarge>
                <Sp h={20}/>
                { content.sectionThreeItems.map(( item : any, index : number )=><FaqItemDesktop key={index} title={item.q}>{item.a}</FaqItemDesktop>)}
              </div>
            </ContainerSplit>
          </div>
        </Center>

        <Sp h={110}/>
        <div style={{ position : 'absolute', transform : 'translate(0px,-20px)'}} ref={getRefs()['Playlist']}/>

        <Center>
        
          <div style={{ display : 'inline-block', width : 1040, textAlign : 'left'}}>
          
            <div style={{ maxWidth : 570, padding : 10 }}>
              <TitleII>{content.sectionFourTitle}</TitleII>
              <Sp h={7.5}/>
              <SubtitleLarge>{content.sectionFourSubtitle}</SubtitleLarge>
              <Sp h={15}/>


              <TextI>
                {content.sectionFourDescription.p1}
              </TextI>
              <Sp h={15}/>


              <TextI>
                {content.sectionFourDescription.p2}
              </TextI>
              &nbsp; 
              <LinkText href={"https://geo.itunes.apple.com/us/album/rock-n-roll-part-2/326120857?i=326120870&mt=1&app=music&itscg=30200&itsct=afftoolset_1&at=1001lINj"}>
                click here
              </LinkText>
              <Sp h={15}/>
              
              <TextI style={{ fontStyle : 'italic' }}>
                {content.sectionFourDescription.p3}
              </TextI>
            </div>
            <Sp h={60}/>

          </div>
        </Center>
        
        
            
        <Center>
        
          <div style={{ display : 'inline-block', width : 1040, textAlign : 'left'}}>

        <div>
          <X align='space-between'>
          <div style={{ display : 'inline-block', width : 320, marginLeft : 0}}>
              <ExamplePlaylist titles={content.playlists.a}/>
          </div>
          <div style={{ display : 'inline-block', width : 320, marginLeft : 0}}>
            <ExamplePlaylist titles={content.playlists.b}/>
          </div>
          <div style={{ display : 'inline-block', width : 320}}>
              <ExamplePlaylist titles={content.playlists.c}/>,
          </div>
          </X>
        </div>
        </div>
      </Center>
      <Sp h={110}/>
      {/* <img width={'100%'} height={200} src={gradientBgClipped1440x216} alt=""/> */}
      <div style={{ overflow : 'hidden', backgroundImage : 'linear-gradient(to bottom, #008080, rgba(0, 128, 128,0.5))', marginLeft : 10, width : 'calc(100% - 20px)', height : 200, borderRadius : '80px 80px 0 0', backgroundColor : ''}}>
      <div style={{ overflow : 'hidden', backgroundRepeat : 'no-repeat', backgroundSize : '100% auto', backgroundImage : `url(${lineBgMobileLight})`, backgroundPosition : '', width : '100vw', height : 500}}>
        {/* <img style={{ width : '100vw', marginLeft : 0, marginTop : 40 }} height={500} src={lineBgMobileLight}/> */}
        <Center>
          <Sp h={10}/>
          <div style={{ width : 1040 - 80, marginLeft : -30  }}>
              <Sp h={40}/>
              <div style={{ float : 'left'}}>
                <TitleFooter>
                  {content.footer.title}
                </TitleFooter> 
                <TitleFooter style={{ display : 'block'}}>
                  {content.footer.subtitle}
                </TitleFooter> 
              </div>
              <div style={{ float : 'right'}}>
                  <SoftLink href={links.appleAppStore}>
                      <img width={180} height={52} style={{ marginRight : 15 }} src={appstore} alt=""/>
                  </SoftLink>
                  <SoftLink href={links.googlePlayStore}>
                      <img width={180} height={52} style={{ marginLeft : 15 }} src={playstore} alt=""/>
                  </SoftLink>
              </div>
          </div>
        </Center>

        <Center>

          <div style={{ width : 1040 - 80, marginLeft : -30, marginTop : 20  }}>
              <div style={{ height : 2, backgroundColor : 'white', width : '100%'}}/>
              <Sp h={20}/>
              <div style={{ float : 'left'}}>
              <SoftLink href={links.email}>
                  <Email>
                    {links.email}
                  </Email>
              </SoftLink>
              </div>
              <div style={{  width : 180, display : 'block', float :  'right'}}>
                <X align='space-between'>
                  <SoftLink href={links.twitter}><img src={Twitter}/></SoftLink>
                  <SoftLink href={links.facebook}><img src={Facebook}/></SoftLink>
                  <SoftLink href={links.instagram}><img src={Instagram}/></SoftLink>
                </X>
              </div>
          </div>
        </Center>
        <Sp h={35}/>
        <div style={{ width : '100%', textAlign : 'center', height : 30, backgroundColor : ''}}/>
        <Sp h={35}/>
      </div>
      </div>
    </>
  )
}


const ExamplePlaylistItem = ( props : { title : string, subtitle : string }) => {
  const padding : number = 20 
  return (
      <div style={{ backgroundColor : 'white', borderRadius : 10, height : 100, width : '100%'  }}>
        <div style={{ display : 'inline-block', width : 64 + padding + padding }}>
          <div style={{ padding : 20 }}>
            <img width={64} height={64} src={exampleAlbum} alt=""/>
          </div>
        </div>
        <div style={{ display : 'inline-block', width : `calc(100% - ${64 + padding + padding}px)` }}>
          
            <GridRow height={64 + padding*2}>
                <p style={{ lineHeight : '24px', ...getFontInter(), color : '#072424', fontSize : 16 }}>{props.title}</p>
                <p style={{ lineHeight : '18px' , ...getFontInter(), color : '#072424', fontSize : 12 }}>{props.subtitle}</p>
            </GridRow>
          
        </div>  
      </div> 
  )
}




const StaticCarouselRadioGroup = ( props : { desktop ?: boolean, items : any[], sx ?: { top ?: any }, height ?: number  }) => {
    const [current, setCurrent ] = useState(0)
    const [nextHover, setNextHover ] = useState(false)
    const [prevHover, setPrevHover ] = useState(false)
    const onClick = ( value : number ) => setCurrent( value )
    const minHeight : number = props.desktop ? 612 : 460 
    const next = () => {
        let updatedIndex : number = current + 1 < props.items.length ? current + 1 : 0 
        setCurrent( updatedIndex ) 
    }
    const prev = () => {
      let updatedIndex : number = current - 1 >= 0 ? current - 1 :  props.items.length - 1
      setCurrent( updatedIndex ) 
    }

    const swipeHandlers = useSwipe({ 
        onSwipedLeft: () => prev(), 
        onSwipedRight: () => next()
    });

    return (<>
        <div {...swipeHandlers}>
          { props.desktop && 
            <div onClick={prev} style={{ minHeight, display : 'inline-block' }}>
                <GridRow height={minHeight}>
                    <div style={{ marginRight : 30, width : 32, height : 32, backgroundColor : '', cursor : 'pointer'}}>
                        <img onMouseEnter={()=>setPrevHover(true)} onMouseLeave={()=>setPrevHover(false)} src={ prevHover ? btnPrevHover : btnPrev}/>
                    </div>
                </GridRow>
            </div>
          }
          <div style={{ display : 'inline-block' }}>
            <Center>
              <div style={{ minHeight, backgroundColor : '', ...props?.sx?.top }}>
                  {props.items[current]}
              </div>
            </Center>
            <Sp h={10}/>


            <Center>
              <div style={{ display : 'inline-block', cursor : 'pointer', width : '100%', textAlign : 'center'}}>
                <div style={{ display : 'inline-block', width : 200 }}>
                  { props.items.map(( item : any, index : number )=><StaticCarouselRadio key={index} focus={index===current} onClick={()=>onClick(index)}/>)}
                </div>
              </div>
            </Center>
          </div>
          { props.desktop && 
            <div onClick={next} style={{ minHeight, display : 'inline-block' }}>
                <GridRow height={minHeight}>
                    <div style={{ marginLeft : 30, width : 32, height : 32, backgroundColor : '', cursor : 'pointer'}}>
                      <img onMouseEnter={()=>setNextHover(true)} onMouseLeave={()=>setNextHover(false)} src={ nextHover ? btnNextHover : btnNext}/>
                    </div>
                </GridRow>
            </div>
          }
        </div>
    </>)
}



const StaticCarouselRadio = ( props : { focus ?: boolean, onClick : ( value : number ) => void  }) => {
    return (<>
        <div style={{ display : 'inline-block' }}  onClick={props.onClick as any} >
          <img style={{ display : 'inline-block', margin : 5 }} src={ props.focus ? carouselRadioFocus : carouselRadioBlur } alt=""/>
        </div>
    </>)  
}

const FaqItem = ( props : { object ?: boolean, title : string, children ?: any }) => {
  const [ toggle, setToggle ] = useState(false)

  const onClick = () => setToggle(!toggle)

  console.log( )

  return (<>
    <div onClick={onClick} style={{ transition : '300ms', backgroundColor : toggle ? 'white' : 'transparent', borderRadius : 10, textAlign : 'left', display : 'inline-block', width : '100%', cursor : 'pointer', minHeight : 70, minWidth : 376*0.6 }}>
        <div style={{ padding: 10, paddingTop : 0, marginTop : 0, textAlign : 'left', width : 280, minHeight : 72, backgroundColor : ''}}>
            
            <div style={{ display : 'inline-block', width : 'calc(100% - 40px)'}}>
              <GridRow style={{ maxWidth : 220 }} height={72}>
                
                <TitleBullet>{ props.title}</TitleBullet>
              </GridRow>
            </div>
            
            <div style={{ display : 'inline-block', width : 40 }}>
                <GridRow style={{ backgroundColor : '', width : '100%'}} height={72}>
                    <ChevronDown style={{ marginLeft : 20, transition : '400ms', transform : toggle ? 'rotate(180deg)' : 'rotate(0deg)'}} size={18}/>
                </GridRow>
            </div>

            <div style={{ display : toggle ? '' : 'none' }}>
                <div style={{ width : 'calc(100% - 20px)', height : 1, marginTop : -4, backgroundColor : '#CAC4D0', marginBottom : 12}}/>
                <div style={{ padding : 5, paddingTop : 0 }}>
                  { typeof props.children === 'object'
                    ? <>{ props.children }</>
                    : <TextBullet>{ props.children }</TextBullet>
                  }
                </div>
            </div>
        </div>
        
    </div>



  </>)
}



const FaqItemDesktop = ( props : { title : string, children ?: any }) => {
  const [ toggle, setToggle ] = useState(false)

  const onClick = () => setToggle(!toggle)

  const maxWidth : number = 570 //

  return (<>
    <div onClick={onClick} style={{ transition : '300ms', backgroundColor : toggle ? 'white' : 'transparent', borderRadius : 10, maxWidth : maxWidth + 30, textAlign : 'left', display : 'inline-block', width : '100%', cursor : 'pointer', minHeight : 70, minWidth : 376*0.6 }}>
        <div style={{ padding: 10, paddingTop : 0, marginTop : 0, textAlign : 'left', width : maxWidth, minHeight : 72, backgroundColor : ''}}>
            
            <div style={{ display : 'inline-block', width : 'calc(100% - 40px)'}}>
              <GridRow style={{ maxWidth : maxWidth - 60 }} height={72}>
                
                <TitleBullet>{ props.title}</TitleBullet>
              </GridRow>
            </div>
            
            <div style={{ display : 'inline-block', width : 40 }}>
                <GridRow style={{ backgroundColor : '', width : '100%', transform : 'translate(-10px, 0px)'}} height={72}>
                    <ChevronDown style={{ marginLeft : 20, transition : '400ms', transform : toggle ? 'rotate(180deg)' : 'rotate(0deg)'}} size={18}/>
                </GridRow>
            </div>

            <div style={{ display : toggle ? '' : 'none' }}>
                <div style={{ width : 'calc(100% - 10px)', height : 1, marginTop : -4, backgroundColor : '#CAC4D0', marginBottom : 12}}/>
                <div style={{ padding : 10, paddingTop : 0 }}>
                  { typeof props.children === 'object'
                    ? <>{ props.children }</>
                    : <TextBullet>{ props.children }</TextBullet>
                  }
                </div>
            </div>
        </div>
        
    </div>



  </>)
}


export default App;
